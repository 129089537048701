import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  .g {
    transform-origin: top;
    animation: swing 2s ease-in-out infinite;

    @keyframes swing {
      0% {
        transform: rotate(-5deg);
      }
      50% {
        transform: rotate(5deg);
      }
      100% {
        transform: rotate(-5deg);
      }
    }
  }
`;
const ComingSooing = () => {
  return (
    <Container>
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 584.58 123.04"
      >
        <defs></defs>
        <path d="M166.42,79.5c-.76-.84-1.48-1.62-2.23-2.45-.54,1.09-1.03,2.08-1.61,3.24-4.1-1.55-3.31-5.07-3.15-7.77,.32-5.19,1.34-10.34,1.96-15.53,.11-.93-.07-2.11-.58-2.87-1.12-1.7-1.6-3.16-1.17-5.38,.36-1.89-.77-4.4-3.59-3.98-5.5,.82-7.33,1.85-7.2,7.48,.1,4.15,1.28,8.26,1.71,12.4,.33,3.21,.31,6.45,.22,9.67-.01,.55-1.17,1.07-2.23,1.98,0-1.22,.17-2.01-.03-2.12-3.48-1.88-1.84-5.16-2.23-7.88-.93-6.41-1.56-12.88-2.89-19.2-.84-4.03-7.04-5.18-10.21-2.33-.99,.88-2.16,1.56-2.76,1.98-5.09,10.28,1.18,20.45-1.18,31.16-.69-1.11-1.38-2.22-2.07-3.33-1.34-1.2-3.16-1.58-3.24-4.71-.14-6.02-.05-11.95,1.1-17.9,.49-2.52,.11-5.22,.08-7.84-.03-1.98,.22-3.41,2.89-2.91,.7,.14,1.56-1.2,2.47-1.57,4.41-1.79,8.64-2.25,12.47,1.5,1.42,1.38,3.21,2.38,5.54,4.06,1.09-1.29,2.43-2.92,3.8-4.53,.19-.23,.46-.47,.75-.55,2.94-.94,9.09,2.78,10.55,5.6,3,5.82,2.48,12.18,3.35,18.33,.12,.82-.55,1.76-.86,2.64-.33,.24-.68,.48-1.02,.73-1.07-2.03-1.74-.93-2.07,.36-.36,1.39-.64,2.87-.55,4.28,.08,1.08,.79,2.11,1.29,3.36,.93-3.45,1.64-6.09,2.35-8.74,1.3,1.57,1.66,10.79,.31,12.8Z" />
        <path d="M106.85,46.8c-3.56-4.06-8.52-7.3-13.16-6.76-5.13-.2-9.84,2.16-10.79,4.54-.36,.9-.87,1.75-1.26,2.65-.34,.79-.65,1.6-.88,2.43-1.07,3.79-2.76,7.56-2.98,11.4-.23,4.07,.31,8.21,3.25,11.86,3.17,3.92,8.83,8.21,14.42,7.25,7.68-1.32,12.66-6.45,16.45-12.85,.81-1.37,1.37-3.04,1.5-4.62,.54-6.35-2.8-11.6-6.55-15.87Zm-1.62,20.8c.43,.49,1.31,1.07,1.24,1.51-.16,.97-.78,1.87-1.22,2.8-2.67-2.18-3.85,.55-5.25,1.79-2.51,2.24-5.54,2.52-8.33,.62-.92-.64-2.22-.75-3.34-1.1,1.21,1.22,2.42,2.43,3.63,3.65-.24,.28-.48,.56-.73,.84-2.2-1.36-4.41-2.72-6.61-4.08,.04-.27,.07-.55,.11-.82l2.71-.28c-1.01-.96-1.93-2.03-3.04-2.85-2.63-1.93-3.97-7.53-2.55-10.4,.82-1.67,1.21-3.54,1.91-5.28,1.35-3.34,2.75-6.67,4.25-9.95,.2-.46,1.04-.62,.92-.56,1.57,1.85,2.87,3.37,4.17,4.9,.33-.28,.68-.56,1.01-.85-.97-1.07-1.94-2.15-3.03-3.37,2.47-.13,5.48-1.22,6.98-.16,3.1,2.18,6.07,5.13,7.89,8.43,4.25,7.68,3.76,9.54-.7,15.16Z" />
        <g>
          <path d="M193.35,44.46c-.45,4.57-1.12,9.12-1.64,13.68-.99,8.73-3.51,16.8-6.98,22.15-1.18-.33-2.36-.65-3.87-1.06-.09-3.29-.04-6.63,1.46-10.27,1.56-3.78,1.07-8.36,2.06-12.44,.81-3.31,2.38-6.46,3.85-9.57,.94-1.98,2.33-3.76,3.51-5.62,.35-.14,.7-.27,1.05-.41,.2,1.17,.67,2.38,.55,3.53Z" />
          <path d="M196.06,27.71c-2.07,.27-4.91,.75-7.77,.91-.65,.04-1.88-1.02-1.96-1.68-.63-5.28,.89-8.04,5.55-9.96,1.02-.41,3.05-.41,3.47,.19,.9,1.29,1.33,3.09,1.44,4.72,.11,1.69-.39,3.41-.72,5.82Z" />
        </g>
        <g className="g">
          <path d="M274.79,47.95c-.95-.33-1.79-1.03-2.76-1.24-2.43-.53-5.25-1.83-7.28-1.09-2.7,.99-4.95,3.47-7.13,5.58-2.72,2.65-5.09,5.66-7.77,8.36-2.77,2.8-4.11,11.04-1.63,14.13,3.26,4.06,8.58,7.55,16.2,5.34,2.54-.74,2.8-.33,2.87,2.45,.03,1.15,.87,2.37,.68,3.44-.6,3.31-1.43,6.59-2.35,9.82-.24,.84-1.18,1.48-1.79,2.21-.33,1.34-.23,3.16-1.09,3.9-1.28,1.12-2.89,2.81-5.14,1.05-.79-.63-2.1-.6-4.03-.98-.06-.01-.14-.03-.21-.04-.07-.01-.15-.04-.23-.05,1.19,1.32,1.64,1.82,2.04,2.27-2.11,.65-2.14,.62-5.59-4.32-.46,.35-.92,.7-1.39,1.05-.16,1.17-.31,2.33-.46,3.5-.82,4.03,2.75,4.1,4.81,5.4,.39-.61,.79-1.22,1.34-2.07,1.49,2.3,3.24,2.36,5.16,1.21,.56-.33,.96-1.02,1.55-1.23,1.93-.68,4.15-.85,5.81-1.92,1.51-.97,2.44-2.86,3.61-4.35,1.27-1.62,3.37-3.14,3.62-4.9,.8-5.68,.91-11.44,1.3-17.17,.05-.7,.16-1.4,.36-2.07,1.78-6.02,3.73-12.01,5.32-18.08,1.24-4.8-1.11-8.56-5.82-10.21Zm-7.26,22.42c-2.77,2.03-4.7,4.08-8.46,3.78-4.17-.33-8.47-5.34-6.89-9.26,1.42-3.5,3.31-6.98,7.72-7.92,1.24-.26,2.31-1.29,3.46-1.97-.11-.16-.21-.32-.31-.48,1.51-.18,3.48-1.04,4.45-.4,2.04,1.35,4.38,3.21,5.12,5.36,1.67,4.89-.97,7.87-5.09,10.89Z" />
          <path d="M262.88,97c-2.03-.01-4.07-.11-6.09-.01-2.11,.1-3.76,.76-2.42,3.73-.26,.06-.52,.14-.79,.21-.04,.01-.09,.02-.14,.04-.02-.03-.04-.05-.07-.08-1.64-1.87-3.29-3.76-5.42-6.18,.12,2.5,.19,3.82,.26,5.13-.16,1.17-.31,2.33-.46,3.5-.82-.05-1.65-.1-2.87-.17,.3-1.63,.58-3.14,.86-4.66-2.6-1.81-4.33-3.45-.39-6.17,1.54-1.07,2.06-3.61,3.56-6.46,0,2.29,.09,3.73-.03,5.16-.16,1.91,1.97,4.32,4,4.34,3.16,.01,6.32,0,9.54,0,.14,.5,.31,1.06,.47,1.63Z" />
          <path
            class="cls-1"
            d="M253.37,100.88c.07,.01,.14,.03,.21,.04-.04,.01-.09,.02-.14,.04-.02-.03-.04-.05-.07-.08Z"
          />
        </g>
        <g>
          <path d="M69.03,66.55c-3.1,1.25-6.14,2.64-9.18,4.04-.79,.36-1.49,.93-2.23,1.4-.53-1-1.37-1.94-1.52-2.99-.26-1.7-.07-3.46-.07-5.29-1.74,1.55-3.33,3.08-5.04,4.46-1.78,1.43-3.68,2.72-5.46,4.03-4.31,.73-7.49-1.56-9.45-5.42-1.96-3.83-3.52-7.87-5.26-11.81,.23-.14,.45-.27,.67-.4,1.55,2.06,3.09,4.11,4.64,6.17,.11-1.25-.23-2.34-.79-3.3-1.91-3.27-4.66-6.24-3.49-10.54,.93-3.46,1.8-6.93,2.74-10.38,.31-1.15,.42-2.82,1.21-3.31,2.2-1.35,4.72-2.2,7.1-3.26,1.37-.61,2.77-1.18,4.04-1.95,.16-.11-.58-1.36-.42-1.96,.1-.37,1.08-.7,1.68-.73,2.65-.08,5.31-.04,8.8-.04-1.83-1.38-2.87-2.16-3.73-2.81,1.2-.9,2.24-1.67,3.77-2.81-4.74-.87-8.53-1.56-12.33-2.25,.09-.33,.18-.65,.26-.97-1.34,.96-2.84,1.76-3.99,2.89-3.13,3.1-5.73,6.88-9.23,9.42-5.82,4.21-6.27,11.24-9.74,16.63-1.02,1.57-.44,4.08-1.02,6.04-2.31,7.88-1.43,15.36,3.83,21.61,2.92,3.46,6.31,7.08,11.9,6.72,3.91-.24,7.87,.41,11.81,.52,1.38,.04,3.02,.05,4.12-.62,6.17-3.75,12.2-7.7,18.79-11.91-1.28-.66-2.01-1.35-2.4-1.19Zm-39.44,1.35c-2.05-5.53-5.18-10.88-3.95-17.2,.55-2.82,.98-5.68,1.47-8.52,.3,.03,.6,.06,.9,.09-.09,1.86-.18,3.71-.28,5.57,.63,.83,1.66,1.58,1.81,2.47,.43,2.57,.52,5.19,.2,8.39-.6-.87-1.22-1.74-1.82-2.6,1.1,2.78,1.95,5.69,3.36,8.3,1.19,2.19,3.04,4.03,4.91,6.42-3.73,.92-5.8-.74-6.6-2.92Zm17.68,8.83c-.16-.43-.31-.86-.46-1.29,2.98-1.13,5.96-2.26,8.95-3.39,.23,.71,.48,1.42,.72,2.13-3.07,.85-6.14,1.7-9.2,2.55Z" />
          <path d="M46.25,69.38c-2.04,1.23-6.39-2.06-9.33-8.31,3.66,3.26,6.56,5.84,9.33,8.31Z" />
        </g>
        <g>
          <path d="M234.72,80.14c-.69,.05-1.39,.09-2.08,.14-.38-2.82-1.1-5.64-1.09-8.45,.02-5.35-1.81-10.11-3.83-14.88-.83-1.97-1.74-3.95-2.21-6.02-1.28-5.58-5.91-6.56-11.24-8.24-.08,4.22-.47,8.21-.14,12.13,.34,4.04,1.71,7.99,2.03,12.04,.25,3.03-.44,6.14-1.24,9.18-3.92-7.4-6.88-14.75-6.87-23.14,.01-4.39,.78-9.09-2.32-13.26-1.14-1.52-.6-4.27-.83-6.47,.48-.14,.95-.28,1.42-.43,1.59,3.42,3.19,6.83,4.81,10.31q1.96-.75,.16-3.29c2.69-2.53,4-2.53,5.83,.27-.05-.85-.08-1.42-.16-2.75,2.94,2.42,5.43,4.45,7.77,6.37,0,0,.03-.58,.06-1.17l.92-.53c1.47,2.54,2.94,5.07,4.13,7.14,.48-.99,1.17-2.4,1.85-3.82-.38,6.37,1.56,12.07,2.2,17.89,.63,5.62,.59,11.32,.84,16.98Z" />
          <path d="M227.52,41.58c-2.79-1-5.4-1.93-8.53-3.06,3.63-1.07,7.54,.43,8.53,3.06Z" />
        </g>
        <path d="M449.96,48.13c-3.56-4.06-8.52-7.3-13.16-6.76-5.13-.2-9.84,2.16-10.79,4.54-.36,.9-.87,1.75-1.26,2.65-.34,.79-.65,1.6-.88,2.43-1.07,3.79-2.76,7.56-2.98,11.4-.23,4.07,.31,8.21,3.25,11.86,3.17,3.92,8.83,8.21,14.42,7.25,7.68-1.32,12.66-6.45,16.45-12.85,.81-1.37,1.37-3.04,1.5-4.62,.54-6.35-2.8-11.6-6.55-15.87Zm-1.62,20.8c.43,.49,1.31,1.07,1.24,1.51-.16,.97-.78,1.87-1.22,2.8-2.67-2.18-3.85,.55-5.25,1.79-2.51,2.24-5.54,2.52-8.33,.62-.92-.64-2.22-.75-3.34-1.1,1.21,1.22,2.42,2.43,3.63,3.65-.24,.28-.48,.56-.73,.84-2.2-1.36-4.41-2.72-6.61-4.08,.04-.27,.07-.55,.11-.82l2.71-.28c-1.01-.96-1.93-2.03-3.04-2.85-2.63-1.93-3.97-7.53-2.55-10.4,.82-1.67,1.21-3.54,1.91-5.28,1.35-3.34,2.75-6.67,4.25-9.95,.2-.46,1.04-.62,.92-.56,1.57,1.85,2.87,3.37,4.17,4.9,.33-.28,.68-.56,1.01-.85-.97-1.07-1.94-2.15-3.03-3.37,2.47-.13,5.48-1.22,6.98-.16,3.1,2.18,6.07,5.13,7.89,8.43,4.25,7.68,3.76,9.54-.7,15.16Z" />
        <path d="M408.56,48.68c-3.56-4.06-8.52-7.3-13.16-6.76-5.13-.2-9.84,2.16-10.79,4.54-.36,.9-.87,1.75-1.26,2.65-.34,.79-.65,1.6-.88,2.43-1.07,3.79-2.76,7.56-2.98,11.4-.23,4.07,.31,8.21,3.25,11.86,3.17,3.92,8.83,8.21,14.42,7.25,7.68-1.32,12.66-6.45,16.45-12.85,.81-1.37,1.37-3.04,1.5-4.62,.54-6.35-2.8-11.6-6.55-15.87Zm-1.62,20.8c.43,.49,1.31,1.07,1.24,1.51-.16,.97-.78,1.87-1.22,2.8-2.67-2.18-3.85,.55-5.25,1.79-2.51,2.24-5.54,2.52-8.33,.62-.92-.64-2.22-.75-3.34-1.1,1.21,1.22,2.42,2.43,3.63,3.65-.24,.28-.48,.56-.73,.84-2.2-1.36-4.41-2.72-6.61-4.08,.04-.27,.07-.55,.11-.82l2.71-.28c-1.01-.96-1.93-2.03-3.04-2.85-2.63-1.93-3.97-7.53-2.55-10.4,.82-1.67,1.21-3.54,1.91-5.28,1.35-3.34,2.75-6.67,4.25-9.95,.2-.46,1.04-.62,.92-.56,1.57,1.85,2.87,3.37,4.17,4.9,.33-.28,.68-.56,1.01-.85-.97-1.07-1.94-2.15-3.03-3.37,2.47-.13,5.48-1.22,6.98-.16,3.1,2.18,6.07,5.13,7.89,8.43,4.25,7.68,3.76,9.54-.7,15.16Z" />
        <g>
          <path d="M494.75,86.54c-.69,.05-1.39,.09-2.08,.14-.38-2.82-1.1-5.64-1.09-8.45,.02-5.35-1.81-10.11-3.83-14.88-.83-1.97-1.74-3.95-2.21-6.02-1.28-5.58-5.91-6.56-11.24-8.24-.08,4.22-.47,8.21-.14,12.13,.34,4.04,1.71,7.99,2.03,12.04,.25,3.03-.44,6.14-1.24,9.18-3.92-7.4-6.88-14.75-6.87-23.14,.01-4.39,.78-9.09-2.32-13.26-1.14-1.52-.6-4.27-.83-6.47,.48-.14,.95-.28,1.42-.43,1.59,3.42,3.19,6.83,4.81,10.31q1.96-.75,.16-3.29c2.69-2.53,4-2.53,5.83,.27-.05-.85-.08-1.42-.16-2.75,2.94,2.42,5.43,4.45,7.77,6.37,0,0,.03-.58,.06-1.17l.92-.53c1.47,2.54,2.94,5.07,4.13,7.14,.48-.99,1.17-2.4,1.85-3.82-.38,6.37,1.56,12.07,2.2,17.89,.63,5.62,.59,11.32,.84,16.98Z" />
          <path d="M487.55,47.98c-2.79-1-5.4-1.93-8.53-3.06,3.63-1.07,7.54,.43,8.53,3.06Z" />
        </g>
        <g>
          <path d="M371.45,59.62c-.33-.06-.66-.11-.99-.18-.81,2.38-1.74,4.74-2.41,7.15-1.32,4.75-2.87,9.17-8.41,10.69-.9,.25-1.48,1.67-2.26,2.5-.03,.03-.6-.44-.92-.68,0-.23,0-.46,.01-.68,.5-.44,1.02-.89,1.96-1.71-4.01-1.26-7.75-1.37-11.36-.09-1.3,.46-3.9-1.73-3.98,1.64l-.24,.21c-2.68-.91-5.36-1.81-8.04-2.73-.85-.3-1.67-.66-2.51-1,.65-.9,1.29-1.8,1.87-2.62-1.71-3.2-4.39-2.42-6.88-2.27-.68-2.11,0-4.81-3.02-5.9-.59-.21-.71-2.18-.74-3.34-.03-1.12,.35-2.26,.48-2.99,2.72-.53,5.38-1.05,8.06-1.57,.08,.18,.16,.38,.23,.57-1.37,.67-2.73,1.34-3.57,1.76,3.26,2.31,6.24,4.41,9.2,6.51-1.06,.5-2.12,1.02-2.99,1.43-1.42-1.15-2.75-2.22-4.08-3.29,.21,5.18,5.95,8.27,8.9,6.81,3.53,1.34,6.32,2.49,9.16,3.44,2.25,.75,2.98-.41,2.63-2.53,2.39,.55,6.49-1.56,7.69-3.95,.55,.43,1.1,.86,1.64,1.28,4.17-4.62,4.81-10.03,1.54-12.6-.31,.72-.62,1.44-.92,2.15-.42-.79-.97-1.54-1.21-2.39-.11-.43,.5-1.02,.51-1.55,.11-4.51-4.22-5.49-6.53-8.07-1.12-1.26-2.42-2.35-3.67-3.48-.37-.33-.84-.8-1.27-.8-3.47-.06-6.95-.11-10.42,0-5.65,.16-10.06-3.04-11.14-8.46-.53-2.69-.68-5.45-.99-8.18,.43-.08,.87-.15,1.32-.22,.14,.43,.26,.85,.4,1.28,.21-.05,.43-.09,.63-.14-.19-.81-.38-1.62-.6-2.53,.95-1.06,2.11-2.3,3.2-3.6,3.66-4.32,8.9-4.4,13.84-5.06,5.65-.75,9.78,2.41,13.58,6.03,.36,.33,.45,1.06,.46,1.61,.05,2.12,.02,4.23,.02,6.56-3.02-1.66-6.56-3.63-10.12-5.55-8.22-4.42-11.77-1.15-13.51,6.56-.61,2.69,1.18,4.72,3.76,5.42,5.62,1.53,11.33,2.73,16.95,4.24,1.17,.31,2.13,1.34,3.51,2.25,.78,.34,2.35,.57,3.12,1.49,2.37,2.81,4.38,5.92,6.64,8.85,1.32,1.71,2.25,3.3,.89,5.49-.28,.47,.35,1.5,.56,2.28Z" />
          <path d="M356.48,78.44c0,.23,0,.46-.01,.68-3.12,.53-6.23,1.13-9.37,1.58-3.68,.52-7.38,1.02-11.09,1.27-.98,.07-2.03-.78-2.94-1.72,3.68,1.05,6.88,.46,9.8-1.76l.24-.21c4.44,.78,8.9,1.77,13.38,.16Z" />
          <path d="M334.18,72.13c-.58,.82-1.22,1.72-1.87,2.62-.78,.51-1.57,1.02-2.05,1.34-1.77-2.29-3.51-4.54-5.82-7.51,1.37,.62,2.11,.95,2.86,1.29,2.49-.15,5.16-.93,6.88,2.27Z" />
        </g>
        <path d="M541.88,72.96c.06,2.27-3.54,4.09-9.42,4.2-.27-.32-1.65-1.19-1.73-2.15-.31-4.12,1.99-6.97,5.25-7.03,3.88-.06,5.81,1.56,5.9,4.98Z" />
        <path d="M564.79,75.96c.06,2.27-3.54,4.09-9.42,4.2-.27-.32-1.65-1.19-1.73-2.15-.31-4.12,1.99-6.97,5.25-7.03,3.88-.06,5.81,1.56,5.9,4.98Z" />
        <path d="M521.4,76.96c.06,2.27-3.54,4.09-9.42,4.2-.27-.32-1.65-1.19-1.73-2.15-.31-4.12,1.99-6.97,5.25-7.03,3.88-.06,5.81,1.56,5.9,4.98Z" />
      </svg>
    </Container>
  );
};

export default ComingSooing;
